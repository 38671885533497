<template>
  <div>
    <div
      id="feature-list-container"
      class="ui mobile-column"
    >
      <div class="mobile-fullwidth">
        <h1>Signalements</h1>
      </div>
      <div class="no-padding-mobile mobile-fullwidth">
        <div class="ui large text loader">
          Chargement
        </div>
        <div class="ui secondary menu no-margin">
          <a
            id="show-map"
            :class="['item no-margin', { active: showMap }]"
            data-tab="map"
            data-tooltip="Carte"
            data-position="bottom left"
            @click="$emit('show-map', true)"
          >
            <i
              class="map fitted icon"
              aria-hidden="true"
            />
          </a>
          <a
            id="show-list"
            :class="['item no-margin', { active: !showMap }]"
            data-tab="list"
            data-tooltip="Liste"
            data-position="bottom left"
            @click="$emit('show-map', false)"
          >
            <i
              class="list fitted icon"
              aria-hidden="true"
            />
          </a>
          <div class="item">
            <h4>
              {{ featuresCount }} signalement{{ featuresCount > 1 ? "s" : "" }}
            </h4>
          </div>
          <div
            v-if="
              project &&
                feature_types.length > 0 &&
                permissions.can_create_feature
            "
            id="button-dropdown"
            class="item right"
          >
            <div
              class="ui dropdown button compact button-hover-green"
              data-tooltip="Ajouter un signalement"
              data-position="bottom right"
              @click="toggleAddFeature"
            >
              <i
                class="plus fitted icon"
                aria-hidden="true"
              />
              <div
                v-if="showAddFeature"
                class="menu left transition visible"
                style="z-index: 9999"
              >
                <div class="header">
                  Ajouter un signalement du type
                </div>
                <div class="scrolling menu text-wrap">
                  <router-link
                    v-for="(type, index) in filteredFeatureTypeChoices"
                    :key="type.slug + index"
                    :to="{
                      name: 'ajouter-signalement',
                      params: { slug_type_signal: type.slug },
                    }"
                    class="item"
                  >
                    {{ type.title }}
                  </router-link>
                </div>
              </div>
            </div>
            <div
              v-if="checkedFeatures.length > 0 && massMode.includes('edit') && isOnline"
              id="edit-button"
              class="ui dropdown button compact button-hover-green tiny-margin-left"
              :data-tooltip="`Modifier le${massMode.includes('status') ? ' statut' : 's attributs'} des signalements`"
              data-position="bottom right"
              @click="editFeatures"
            >
              <i
                class="pencil fitted icon"
                aria-hidden="true"
              />
              <div
                v-if="showModifyStatus"
                class="menu left transition visible"
                style="z-index: 9999"
              >
                <div class="header">
                  Modifier le statut des Signalements
                </div>
                <div class="scrolling menu text-wrap">
                  <span
                    v-for="status in availableStatus"
                    :key="status.value"
                    class="item"
                    @click="$emit('edit-status', status.value)"
                  >
                    {{ status.name }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="checkedFeatures.length > 0 && massMode === 'delete-features' && isOnline"
              class="ui button compact button-hover-red tiny-margin-left"
              data-tooltip="Supprimer tous les signalements sélectionnés"
              data-position="bottom right"
              @click="$emit('toggle-delete-modal')"
            >
              <i
                class="grey trash fitted icon"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <section
      id="form-filters"
      class="ui form grid equal width"
    >
      <div
        id="type"
        :class="['field column', { 'disabled': !isOnline }]"
      >
        <label>Type</label>
        <Multiselect
          v-model="form.type"
          :options="featureTypeOptions"
          :multiple="true"
          :searchable="false"
          :close-on-select="false"
          :show-labels="false"
          placeholder="Sélectionner un type"
          track-by="value"
          label="name"
        />
      </div>
      <div
        id="statut"
        :class="['field column', { 'disabled': !isOnline }]"
      >
        <label>Statut</label>
        <Multiselect
          v-model="form.status"
          :options="statusOptions"
          :multiple="true"
          :searchable="false"
          :close-on-select="false"
          :show-labels="false"
          placeholder="Sélectionner un statut"
          track-by="value"
          label="name"
        />
      </div>
      <div
        id="name"
        :class="['field column', { 'disabled': !isOnline }]"
      >
        <label>Nom</label>
        <div class="ui icon input">
          <i
            class="search icon"
            aria-hidden="true"
          />
          <div class="ui action input">
            <input
              v-model="form.title"
              type="text"
              name="title"
              @keyup.enter="resetPaginationNfetchFeatures"
            >
            <button
              id="submit-search"
              class="ui teal icon button"
              @click="resetPaginationNfetchFeatures"
            >
              <i
                class="search icon"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

import { statusChoices, allowedStatus2change } from '@/utils';

const initialPagination = {
  currentPage: 1,
  pagesize: 15,
  start: 0,
  end: 15,
};

export default {

  name: 'FeaturesListAndMapFilters',

  components: {
    Multiselect
  },

  props: {
    showMap: {
      type: Boolean,
      default: true
    },
    featuresCount: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          ...initialPagination
        };
      }
    },
    editAttributesFeatureType: {
      type: String,
      default: null,
    },

  },

  data() {
    return {
      form: {
        type: [],
        status: [],
        title: null,
      },
      lat: null,
      lng: null,
      showAddFeature: false,
      showModifyStatus: false,
      zoom: null,
    };
  },

  computed: {
    ...mapState([
      'user',
      'USER_LEVEL_PROJECTS',
      'isOnline'
    ]),
    ...mapState('feature', [
      'checkedFeatures',
      'massMode',
    ]),
    ...mapState('feature-type', [
      'feature_types',
    ]),
    ...mapState('projects', [
      'project',
    ]),
    ...mapGetters([
      'permissions',
    ]),

    availableStatus() {
      if (this.project && this.user) {
        const isModerate = this.project.moderation;
        const userStatus = this.USER_LEVEL_PROJECTS[this.project.slug];
        const isOwnFeature = true; //* dans ce cas le contributeur est toujours l'auteur des signalements qu'il peut modifier
        return allowedStatus2change(this.user, isModerate, userStatus, isOwnFeature);
      }
      return [];
    },
    featureTypeTitles() {
      return this.feature_types.map((el) => el.title);
    },
    featureTypeOptions() {
      return this.feature_types.map((el) => ({ name: el.title, value: el.slug }));
    },
    statusOptions() {
      //* if project is not moderate, remove pending status
      return statusChoices.filter((el) =>
        this.project && this.project.moderation ? true : el.value !== 'pending'
      );
    },
    filteredFeatureTypeChoices() {
      return this.feature_types.filter((fType) =>
        !fType.geom_type.includes('multi')
      );
    },
  },

  watch: {
    'form.type'(newValue) {
      this.$emit('set-filter', { type: newValue });
      this.resetPaginationNfetchFeatures();
    },
    'form.status': {
      deep: true,
      handler(newValue) {
        this.$emit('set-filter', { status: newValue });
        this.resetPaginationNfetchFeatures();
      }
    },
    'form.title'(newValue) {
      this.$emit('set-filter', { title: newValue });
      this.resetPaginationNfetchFeatures();
    },
  },

  mounted() {   
    window.addEventListener('mousedown', this.clickOutsideDropdown);
  },

  destroyed() {
    window.removeEventListener('mousedown', this.clickOutsideDropdown);
  },

  methods: {
    resetPaginationNfetchFeatures() {
      this.$emit('reset-pagination');
      this.$emit('fetch-features');
    },

    toggleAddFeature() {
      this.showAddFeature = !this.showAddFeature;
      this.showModifyStatus = false;
    },

    editFeatures() {
      switch (this.massMode) {
      case 'edit-status':
        this.toggleModifyStatus();
        break;
      case 'edit-attributes':
        this.displayAttributesForm();
        break;
      }
    },

    toggleModifyStatus() {
      this.showModifyStatus = !this.showModifyStatus;
      this.showAddFeature = false;
    },

    displayAttributesForm() {
      if (this.checkedFeatures.length > 1) {
        this.$router.push({
          name: 'editer-attribut-signalement',
          params: {
            slug_type_signal: this.editAttributesFeatureType,
          },
        });
      } else {
        this.$store.commit('DISPLAY_MESSAGE', {
          comment: 'Veuillez sélectionner au moins 2 signalements pour l\'édition multiple d\'attributs'
        });
      }
    },

    clickOutsideDropdown(e) {
      if (!e.target.closest('#button-dropdown')) {
        this.showModifyStatus = false;
        setTimeout(() => { //* timout necessary to give time to click on link to add feature
          this.showAddFeature = false;
        }, 500);
      }
    },
  }

};
</script>

<style lang="less" scoped>

#feature-list-container {
  display: flex;
  justify-content: space-between;
  .no-padding-mobile {
    width: 100%;
    margin-left: 25%;
    .secondary.menu  #button-dropdown {
      z-index: 10;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

#form-filters {
  margin: 0;
  label + div {
    min-height: 42px;
  }
}

.ui.dropdown .menu .left.menu, .ui.dropdown > .left.menu .menu {
  margin-right: 0 !important;
}

@media screen and (min-width: 767px) {
  #form-filters {
    div.field:first-child { 
      padding-left: 0;
    }
    div.field:last-child {
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  #feature-list-container > .mobile-fullwidth {
    width: 100% !important;
  }

  .no-margin-mobile {
    margin: 0 !important;
  }
  .no-padding-mobile {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-left: 0 !important;
  }

  .mobile-column {
    flex-direction: column;
  }
  #form-filters > .field.column {
    width: 100%;
    padding: 0;
  }
  #form-filters > .field.column:first-child {
    margin-top: 1rem;
  }
  #form-filters > .field.column:last-child {
    margin-bottom: 1rem;
  }
  .map-container {
    width: 100%;
  }
}
</style>

<style>
#form-filters .multiselect__tags {
  white-space: normal !important;
}
#form-filters .multiselect__tag {
  background: var(--primary-color, #008c86) !important;
}
#form-filters .multiselect__tag-icon:focus, #form-filters .multiselect__tag-icon:hover{
  background: var(--primary-highlight-color, #006f6a) !important;
}
#form-filters .multiselect__tag-icon:not(:hover)::after {
  color: var(--primary-highlight-color, #006f6a);
  filter: brightness(0.6);
}
#form-filters .multiselect__option--selected:not(:hover) {
  background-color: #e8e8e8 !important;
}
</style>